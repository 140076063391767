import keyMirror from 'key-mirror';
import { createStandardAction } from "typesafe-actions";
import { Modal, ConfirmModal, AlertModal, CustomModal, ConfirmYesNoModal, ConfirmOkeyModal } from './types'

export const actionTypes = keyMirror({
  SHOW_MODAL: null,
  HIDE_MODAL: null,
});


const at = actionTypes;

export const { SHOW_MODAL, HIDE_MODAL } = actionTypes;

export const actionCreators = {
  showModal: createStandardAction(at.SHOW_MODAL)<Modal>(),
  hideModal: createStandardAction(at.HIDE_MODAL)<Modal>(),
}

export const { showModal, hideModal } = actionCreators;


export { AlertModal, ConfirmModal, CustomModal, ConfirmYesNoModal, ConfirmOkeyModal };


