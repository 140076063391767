import { put, take, takeLatest, select, call } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { actionTypes as console_at } from './actions';
import { takeEvery, eventChannel } from 'redux-saga';
import { getConsoleConnectionData, getWebSocket, getSendRDPData, getSpecialKeyCombination } from './reducer';
import { RDPSendSpecialKey, isWindowsOS } from './components/rdpDecoder';
import { translate } from '../../../l10n';
import { showModal } from "../../sagas/modals/actions";

const t = (str: string, context = 'common') => translate(context, str);

function* createEventChannel(rdpSocket) {
  return eventChannel((emitter) => {
    rdpSocket.onopen = (event) => emitter({ type: console_at.WS_RDP_OPEN, payload: event, websocket: rdpSocket })
    rdpSocket.onclose = (event) => emitter({ type: console_at.WS_RDP_CLOSE, payload: event })
    rdpSocket.onerror = (event) => emitter({ type: console_at.WS_RDP_ERROR, payload: event })
    rdpSocket.onmessage = (event) => emitter({ type: console_at.WS_RDP_MESSAGE, payload: event })

    return () => {
      rdpSocket.close();
    };
  });
}

export function* forceDisconnectConsole() {
  const rdpSocket = yield select(getWebSocket);
  if (rdpSocket) {
    rdpSocket.close();
    yield put({ type: console_at.WS_RDP_DISCONNECT });
  }
}

export function* resetWebSocketChannel() {
  const rdpSocket = yield select(getWebSocket);
  if (rdpSocket) {
    rdpSocket.close();
    yield put({ type: console_at.WS_RDP_DISCONNECT });
  }

  yield call(initializeWebSocketsChannel);
}

export function* closeWebSocketChannel() {
  const rdpSocket = yield select(getWebSocket);

  if (rdpSocket !== null) {
    rdpSocket.close();
    yield put({ type: console_at.WS_RDP_DISCONNECT });
    yield put({ type: console_at.WS_REMOVE_EVENT_LISTENERS });
  }

}

export function* sendWebSocketMessage() {
  const message = yield select(getSendRDPData);
  const rdpSocket = yield select(getWebSocket);
  if (rdpSocket) {
    if (rdpSocket.readyState == rdpSocket.OPEN) {
      rdpSocket.send(message);
    }
  }
}

export function* sendWebSocketSpecialKeys() {
  const rdpSocket = yield select(getWebSocket);
  const keyCombination = yield select(getSpecialKeyCombination);
  if (rdpSocket) {
    if (rdpSocket.readyState == rdpSocket.OPEN) {
      const connectionDetails = yield select(getConsoleConnectionData);
      switch (keyCombination) {
        case "AltCtrlDel":
          if (!isWindowsOS(connectionDetails.vmOS)) {
            yield put(
              showModal({
                type: "confirm",
                message: t('vmConsole.vmConsoleRestartLinuxMessage'),
                onClose: ({ confirm }) => {
                  if (confirm) {
                    let altctrldel = RDPSendSpecialKey(1);
                    rdpSocket.send(altctrldel);
                  }
                }
              }))
          } else {
            let altctrldel = RDPSendSpecialKey(1);
            rdpSocket.send(altctrldel);
          }
          break;
        case "Lock":
          let lock = null;
          if (isWindowsOS(connectionDetails.vmOS)) {
            lock = RDPSendSpecialKey(3);
          }
          else {
            lock = RDPSendSpecialKey(2);
          }
          rdpSocket.send(lock);
          break;
      }
    }
  }
}

export function* sendWebSocketLock() {
  const rdpSocket = yield select(getWebSocket);
  if (rdpSocket) {
    if (rdpSocket.readyState == rdpSocket.OPEN) {

      //rdpSocket.send(message);  
    }
  }
}

export function* initializeWebSocketsChannel() {
  const hasSocket = yield select(getWebSocket);
  if (hasSocket !== null) {
    hasSocket.close();
  }
  const env = process.env.FORCE_NODE_ENV

  const connectionDetails = yield select(getConsoleConnectionData);
  const { accountId, vmId } = connectionDetails;
  try {
    let rdpSocket = null;
    if (env === undefined || env === 'local-development') {
      rdpSocket = new WebSocket("ws://10.10.207.107:9090/wsgate?host=&pcb=" + vmId + "&user=" + accountId + "&perf=0&fntlm=0&nowallp=1&nowdrag=1&nomani=1&notheme=0&nonla=0&notls=0");
    } else {
      const rurl = `wss://${window.location.host}:8443`;
      rdpSocket = new WebSocket(rurl + "/wsgate?host=&pcb=" + vmId + "&user=" + accountId + "&perf=0&fntlm=0&nowallp=1&nowdrag=1&nomani=1&notheme=0&nonla=0&notls=0");
    }
    rdpSocket.binaryType = 'arraybuffer';
    const channel = yield call(createEventChannel, rdpSocket);
    yield put({ type: console_at.WS_ADD_EVENT_LISTENERS });
    while (true) {
      let action = yield take(channel);
      const { payload } = action;
      const { data } = payload;

      switch (typeof (data)) {
        case 'string':
          switch (data.substr(0, 2)) {
            case "T:":
              yield put({ type: console_at.WS_WRITE_DEBUG_CONSOLE, payload: data.substring(2) })
              yield put(showModal({
                type: "alert",
                alertType: "warning",
                message: t('vmConsole.consoleConnectionWasTerminated'),
                onClose: () => {
                  window.history.back();
                }
              }));
              //yield call(resetWebSocketChannel);
              break;
            case "E:":
              yield put({ type: console_at.WS_WRITE_DEBUG_CONSOLE, payload: data.substring(2) })
              yield put(showModal({
                type: "alert",
                alertType: "warning",
                message: t('vmConsole.cannotConnectToConsole'),
                onClose: () => {
                  window.history.back();
                }
              }));
              //this.log.err(data.substring(2));
              //this.fireEvent('alert', data.substring(2));                        
              break;
            case 'I:':
              yield put({ type: console_at.WS_WRITE_DEBUG_CONSOLE, payload: data.substring(2) })
              break;
            case 'W:':
              yield put({ type: console_at.WS_WRITE_DEBUG_CONSOLE, payload: data.substring(2) })
              break;
            case 'D:':
              yield put({ type: console_at.WS_WRITE_DEBUG_CONSOLE, payload: data.substring(2) })
              break;
            case 'S:':
              yield put({ type: console_at.WS_WRITE_DEBUG_CONSOLE, payload: data.substring(2) })
              yield put({ type: console_at.WS_SET_SID, payload: data.substring(2) })
              break;
          }
          break;
        // ... and binary messages for the actual RDP stuff.
        case 'object':
          yield put({ type: console_at.WS_RDP_PROCESS_DATA, payload: data })
          break;
      }
      yield put(action);

    }
  }
  catch (err) {
    console.log(err);
  }
  finally {
    console.log('Can\'t create socket connection!');
  }
}

export const sagas = function* () {
  /*yield takeLatest(
    action =>
      action.type === LOCATION_CHANGE &&
      /vm-console/.test(action.payload.pathname),
    handleConsoleLoading,

  );*/
  yield takeLatest(
    action => action.type === LOCATION_CHANGE &&
      (!action.payload.pathname.includes('/vm-console/') || !action.payload.pathname.includes('/console/')),
    closeWebSocketChannel,
  );
  yield [
    takeEvery(console_at.WS_RDP_CONNECT, initializeWebSocketsChannel),
    takeEvery(console_at.WS_RDP_RESET, resetWebSocketChannel),
    takeEvery(console_at.WS_RDP_DISCONNECT, closeWebSocketChannel),
    takeEvery(console_at.WS_RDP_SEND_MESSAGE, sendWebSocketMessage),
    takeEvery(console_at.WS_RDP_SEND_SPECIAL_KEY, sendWebSocketSpecialKeys),
  ];
};
