import * as React from 'react';
import { Route } from 'react-router-dom';


export interface RouteWithLayoutProps  {
    layout: any;
    component: any;
    path: string;
    exact? : boolean;
}

export default function RouteWithLayout({ layout, component, ...rest }: RouteWithLayoutProps): JSX.Element {
  return (
      <Route
          {...rest}
          render={props =>
                React.createElement(layout, props, React.createElement(component, props))
            }
        />
  );
}
