import * as React from 'react';
import classNames from 'classnames';
import './styles/Checkbox.scss';

export interface CheckboxProps {
  label: string | React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean, evt: React.FormEvent<HTMLInputElement>) => void
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

class Checkbox extends React.Component<CheckboxProps> {
  constructor(props) {
    super(props);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  toggleCheckbox(e) {
    this.props.onChange(e.target.checked, e);
  }

  render() {
    const { label, checked, disabled, onChange, className, ...rest } = this.props;
    const labelClassName = classNames('checkbox', className, {
      'checkbox--disabled': disabled,
    });
    return (
      <label className={labelClassName} {...rest} >
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={this.toggleCheckbox}
        />
        <span className="checkbox__control">{label}</span>
      </label>);
  }
}

export default Checkbox;
