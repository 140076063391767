import * as React from "react";
import { connect } from "react-redux";
import "../styles/CloudCalculator.scss";
// import { translate } from "../../../../l10n";

// const t = (str: string, context = "guest") => translate(context, str);



class ServiceReliability extends React.Component<null> {


    render() {
        return (
            <div>
                <h1>Service Reliability</h1>
            </div>
        );
    }
}


export default connect(null, null)(ServiceReliability);
