import { register } from "../index";

register({
  bundle: "guest",
  resources: {
    fullScreenSlider: {
      registrationButton: "Регистрирай се сега",
      registerNow: "Регистрирай се сега",
      slideOne: {
        title: "Защо Oblak.bg",
        text:
          "Защото сме първият в България публичен доставчик на облачни услуги!",
      },
      slideTwo: {
        title: "Нашата мисия",
        text:
          "Предлагането на качествени продукти, услуги и поддръжка е основна мисия на Oblak.bg при изграждане на стабилни и дългосрочни отношения с нашите клиенти и партньори.",
      },
      slideThree: {
        title: "Какво предоставяме",
        text:
          "Предоставяме Ви напълно автоматизирани IT решения през портала Oblak.bg, където сами и лесно можете да ползвате софтуер, виртуализирана инфраструктура и услуги на принципа „Плащаш само за това, което ползваш“.",
      },
    },
    whySlider: {
      title: "Защо Oblak.bg?",
      slideOne: {
        title: "Услугите на Oblak.bg ",
        paragraphOne:
          "Услугите, които предоставяме са напълно автоматизирани, надежни и помагат за ефективното управление на Вашият бизнес. През нашият портал за управление имате възможност сами да управлявате вашата инстараструктура (IaaS), като по този начин можете да оптимизирате максимално разходите си. ",
        paragraphTwo:
          "Няма да е необходимо да мислите вече за разходи за ток, климатизация и поддъжка за Вашата инфраструктура. Същевременно ние гарантираме непрекъсваемост на услугата и предоставяме БЕЗПЛАТЕН достъп до ресурсите Ви без да начисляваме допълнителни такси.",
      },
    },
    calculator: {
      IaaS: "Инфраструктура (IaaS)",
    },
    registration: {
      title: "Регистрирай се в Oblak.bg",
      offerText: "Сега с 10 GB безплатно",
      offerTextRest: "пространство",
    },
    forgottenPassword: {
      title: "Обновяване на забравена парола",
      submitSuccessfully:
        "Вашето запитване беше изпратено успешно. Благодарим Ви, че се свързахте с нас.",
    },
    resetPassword: {
      title: "Нова парола",
    },
    login: {
      title: "Влезте във Oblak.bg",
      invalidCredentials:
        "Предоставената комбинация на имейл адрес и парола не е вярна. Моля, опитайте отново.",
    },
    inactiveAccount: {
      message:
        "За да завършите Вашата регистрация в Oblak.bg, моля последвайте линка за потвърждаване на Вашия имейл адрес от писмото, което сте получили. В случай, че писмото се е загубило и желаете да Ви изпратим ново писмо, моля използвайте бутона на тази страница.",
      successMessage: "Линк за потвърждаване на регистрацията бе изпратен на посочения от Вас имейл адрес.",
      errorMessage: "Възникна грешка по време на изпращане на линк за потвърждаване на регистрацията. Моля, опитайте отново."
    },
    deactivatedAccount: {
      message: 'Вашият акаунт е деактивиран, ако желаете да го активирате отново, ще Ви изпратим линк за потвърждаване на въведения имейл адрес. В случай, че писмото се е загубило и желаете да Ви изпратим ново писмо, моля използвайте бутона на тази страница.',
      successMessage: "Линк за активиране на акаунта бе изпратен на посочения от Вас имейл адрес.",
      errorMessage: "Възникна грешка по време на изпращане на линк за активиране на акаунт. Моля, опитайте отново."
    },
    guestAccount: {
      setPassword: "Задайте парола",
    },
    confirmRegistration: {
      createdAccount:
        "Създаденият акаунт важи за всички платформи на дружеството <b>Disk.bg</b> и <b>Oblak.bg</b> ",
      diskBGConfReg: "Disk.bg",
      and: " и ",
      oblakBGConfReq: "Oblak.bg",
      sendNewCode: "Изпрати нов код",
      resetCodeSuccess: "Успешно изпратихте нов код за верификация. За да завършите своята регистрация натиснете линка в имейла, който ще получите на посоченият от Вас имейл адрес.",
      resetCodeError: "Изпращането на нов код за верификация не беше успешно!",
      title: "Код За Верификация",
      successfulRegistrationMessage: "Благодарим Ви за потвърждаването на Вашата регистрация. </br> Създаденият акаунт е валиден за всички платформи на дружеството ( <strong>Disk.bg</strong> и <strong>Oblak.bg</strong> ). </br></br> Ако до 5 секунди не бъдете препратени към моят облак, моля натиснете <strong>тук</strong>."
    },
    cloudCalculator: {
      infrastructureIAAS: "Инфраструктура (IaaS)",
      freeLicense: "* Oblak.bg Ви предоставя лиценза за операционна система",
      costFree: "БЕЗПЛАТНО",
    },
    registrationForm: {
      passwordContainName:
        'Паролата не трябва да съдържа стойността на полето "Име" или "Фамилия"',
      passwordContainEmail: 'Паролата не трябва да съдържа стойността на полето "Имейл адрес"'
    },
    sharedConsole: {
      InvalidPassword:
        "Грешен или непълен код за достъп! Проверете отново кода за достъп в съобщението за споделена конзола.",
      SendRestartRequest:
        "От Ваше име е изпратена заявка за рестарт към собственика.",
      expiredSession: "Изтекла сесия на споделената конзола.",
      instructions: "Инструкции",
      requestRestart: "Заяви рестарт",
      sharedConsole: "Споделена Конзола",
    },
  },
});
