import * as React from 'react';
import { OS } from './types'
import RadioGroup from '../../../../shared/components/RadioGroup';
import RadioButton from '../../../../shared/components/RadioButton';


export interface CloudRadioGroupProps {
    selectedOS: OS;
    onChange: (os: string) => void;
}

export const CloudRadioGroup: React.SFC<CloudRadioGroupProps> = (props) => {
    const { selectedOS, onChange } = props;

    const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
        onChange(evt.currentTarget.value);
    };

    const triggerChange = (os: OS) => () => onChange(os);

    return (
        <RadioGroup name="os">
            <div className="cloud-os cloud-os--windows" onClick={triggerChange('windows')}>
                <RadioButton
                    value="windows"
                    checked={selectedOS === 'windows'}
                    onChange={handleChange}
                />
                <i className="icon-windows icon-windows8"></i>
                <span className="cloud-os__label">Windows</span>
            </div>
            <div className="cloud-os cloud-os--linux" onClick={triggerChange('linux')}>
                <RadioButton
                    value="linux"
                    checked={selectedOS === 'linux'}
                    onChange={handleChange}
                />
                <span className="icon-linux"></span>
                <span className="cloud-os__label">Linux</span>
            </div>
        </RadioGroup>
    );
};


export default CloudRadioGroup;