import * as React from 'react';
import './styles/WhyOblak.scss';
import ImageSlider from './ImageSlider';
import * as sliderImage from '../../../assets/images/slider-img.jpg';
import { translate } from '../../../l10n';

const t = (str, context = 'guest') => translate(context, str);

const settings = {
  // fade: true,
  useCSS: true,
  lazyLoad: true,
  dots: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
//   infinite: true,
//   autoplay: true,
//   pauseOnHover: true,
};

export default () => (
    <div className="why">
        <h1 className="why__header">
            {t('whySlider.title')}
        </h1>
        <div className="why__slider-container">
            <ImageSlider {...settings}>
                <div>
                    <div className="why__slider-image-content">
                        <img className="why__slider-image" src={sliderImage} alt="Slide 1" />
                    </div>
                    <div className="why__slider-text-content">
                        <h1 className="why__slider-title">{t('whySlider.slideOne.title')}</h1>
                        <div className="why__slider-text">
                            <p>{t('whySlider.slideOne.paragraphOne')}</p>
                            <br />
                            <p>{t('whySlider.slideOne.paragraphTwo')}</p>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className="why__slider-image-content">
                        <img className="why__slider-image" src="http://placehold.it/730x443&text=Slide2" alt="Slide 2" />
                    </div>
                    <div className="why__slider-text-content">
                        <h1 className="why__slider-title">{t('whySlider.slideOne.title')}</h1>
                        <div className="why__slider-text">
                            <p> Mucius nostro mei cu, mea in invidunt urbanitas. Usu eu augue tibique iracundia, vel solet necessitatibus an. Alia vidisse euismod eum at. Quis erant expetenda at ius, no paulo forensibus mei, quo magna abhorreant ea. Id mea habeo viderer dissentias, posse malorum quaerendum ne eam. Altera accusata mel ex, errem expetenda pri at.</p>
                            <br />
                            <p>Lorem ipsum dolor sit amet, an sea libris fastidii, in eam iisque epicurei, usu in munere mediocritatem. Mucius nostro mei cu, mea in invidunt urbanitas. Usu eu augue tibique iracundia, vel solet necessitatibus an. Alia vidisse euismod eum at. Quis erant expetenda at ius, no paulo forensibus mei, quo magna abhorreant ea. Id mea habeo viderer dissentias, posse malorum quaerendum ne eam. Altera accusata mel ex, errem expetenda pri at.</p>
                        </div>
                    </div>
                </div> */}
                {/* <div>
                    <div className="why__slider-image-content">
                        <img className="why__slider-image" src="http://placehold.it/730x443&text=Slide3" alt="Slide 3" />
                    </div>
                    <div className="why__slider-text-content">
                        <h1 className="why__slider-title">{t('whySlider.slideOne.title')}</h1>
                        <div className="why__slider-text">
                            <p>{t('whySlider.slideOne.paragraphOne')}</p>
                            <br />
                            <p>{t('whySlider.slideOne.paragraphTwo')}</p>
                        </div>
                    </div>
                </div> */}
            </ImageSlider>
        </div>
    </div>
);
