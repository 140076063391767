import validationRules from './validationRules';

export function validateField(fieldName, value) {
  if (window.skipValidation) {
    return null;
  }
  const inputValue = value;
  const rules = validationRules[fieldName];
  if (rules) {
    if (rules.isRequired) {
      if (!inputValue) {
        return rules.isRequired.message;
      }
    }
    if (rules.length) {
      const len = rules.length.value;
      const valueLength = inputValue.length;
      const valid = (valueLength >= len[0] && valueLength <= len[1]);
      if (!valid) {
        return rules.length.message;
      }
    }
    if (rules.phoneLength) {
      const len = rules.phoneLength.value;
      const value = inputValue;
      const regexExp = /[^A-Za-z0-9]/ig;
      const valueNoSymbolsAndSpaces = value.replace(regexExp, "");
      const valueLength = valueNoSymbolsAndSpaces.length;
      const valid = (valueLength >= len[0] && valueLength <= len[1]);
      if (!valid) {
        return rules.phoneLength.message;
      }

    }
    if (rules.phonePattern) {
      const regex = rules.phonePattern.value;
      //phoneRemoveSymbols = new List<string> { "+", "-", " ", "/", "(", ")" };
      const removeRegex = /([+\-()\s])/g;
      const valueToExec = inputValue.replace(removeRegex, "");
      const match = regex.exec(valueToExec);
      const hasMatch = (match && (match.index === 0) && (match[0].length === valueToExec.length))
      if (!hasMatch) {
        return rules.phonePattern.message;
      }
    }
    if (rules.pattern) {
      const regex = rules.pattern.value;
      const match = regex.exec(inputValue);
      const hasMatch = (match && (match.index === 0) && (match[0].length === inputValue.length));
      if (!hasMatch) {
        return rules.pattern.message;
      }
    }
    if (rules.leadsLength) {
      const len = rules.leadsLength.value;
      const value = inputValue;
      const regexExp = /[^\S\r\n]{2,}/gm;
      const valueNoSymbolsAndSpaces = value.replace(regexExp, " ");
      const valueLength = valueNoSymbolsAndSpaces.length;
      const valid = (valueLength >= len[0] && valueLength <= len[1]);
      if (!valid) {
        return rules.leadsLength.message;
      }
    }
    if (rules.vmIDPattern) {
      const regex = rules.vmIDPattern.value;
      const match = regex.test(inputValue);
      //const blq=reg.test(string);
      const hasMatch = match;
      if (!hasMatch) {
        return rules.vmIDPattern.message;
      }
    }
  }
  return null;
}

export default { validateField };
