import keyMirror from "key-mirror";
import { createStandardAction } from "typesafe-actions";
import { VmConfig } from './components/CloudCalculator/types'

export const actionTypes = keyMirror({
  COMPONENT_READY: null,

  LOAD_SETTINGS: null,
  LOAD_SETTINGS_ERROR: null,
  LOAD_SETTINGS_SUCCESS: null,

  UPDATE_PRICE: null,

  CALCULATE_PRICE: null,
  CALCULATE_PRICE_ERROR: null,
  CALCULATE_PRICE_SUCCESS: null,
});

const at = actionTypes;

export const actionCreators = {
  componentReady: createStandardAction(at.COMPONENT_READY)<void>(),
  loadSettings: createStandardAction(at.LOAD_SETTINGS).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/Calculator/LoadSettings',
        },
      }
    })
  ),
  updatePrice: createStandardAction(at.UPDATE_PRICE).map(
    (payload: VmConfig) => ({
      payload: payload,
    })
  ),
  calculatePrice: createStandardAction(at.CALCULATE_PRICE).map(
    (payload: VmConfig) => ({
      payload: payload,
      meta: {
        request: {
          url: '/Calculator/CalculatePrice',
          method: 'POST',
          body: JSON.stringify(payload),
        },
      }
    })
  ),
  calculatePriceSuccess: createStandardAction(at.CALCULATE_PRICE_SUCCESS).map(
    (payload: VmConfig) => ({
      payload: payload,
    })
  ),
}


