import * as React from 'react'
import '../styles/InputRange.scss'
import { MetricId, MetricName } from './types'


export interface InputRangeProps {
  id: MetricId,
  label: MetricName
  steps: {
    value: number;
    label: string
  }[];
  value: number;
  onChange: (obj: { id: MetricId, value: number }) => void;
}


class InputRange extends React.PureComponent<InputRangeProps> {


  onChange = (evt: React.FormEvent<HTMLInputElement>) => {
    const element = evt.currentTarget;
    const index = Number(element.value);
    this.setState({
      currentIndex: index,
    });
    const { id, steps, onChange } = this.props;
    onChange({
      id,
      value: steps[element.value].value
    });
  }

  render() {
    const { steps, label, value } = this.props;
    const currentIndex = steps.findIndex((item) => item.value === value)
    const maxIndex = steps.length - 1;
    const percentage = (currentIndex / maxIndex) * 100;
    const [count, mertic] = steps[currentIndex].label.split(/\s+/);
    const attribute = { orient: "vertical" };
    return (
      <div className="cloud-calculator__slider-content">
        <div className="range-slider">
          <input
            ref={"slider"}
            type="range"
            min="0"
            max={maxIndex}
            value={currentIndex}
            onChange={this.onChange}
            {...attribute}
          />
          <div className="range-slider__bar" />
          <div className="range-slider__thumb" style={{ bottom: `${percentage}%` }}>
            <span>{count}</span>
            <br />
            <span>{mertic}</span>
          </div>
        </div>
        <span className="cloud-calculator__slider-label">{label}</span>
      </div>);
  }
}

export default InputRange;