import React from 'react';
import { connect } from 'react-redux';
//import { string, shape, func } from 'prop-types';
import { Link } from 'react-router-dom';
import '../../../shared/pages/styles/formPage.scss';
import Form, { IForm } from '../../../shared/components/Form';
import { email, password } from '../../../shared/components/Form/js/fields';
// import $ from '../../../scripts/common/querySelector';
import { requestToken } from '../../../scripts/common/db';
import { showModal } from '../../../shared/sagas/modals/actions';
import { translate } from '../../../l10n';
import { actionCreators as ac } from '../../submit-form/actions';
import { isLoading, getData } from '../../submit-form/reducer/login';

const t = (str, context = 'common') => translate(context, str);

declare global {
  interface Window {
    loginAttempt:any;
  }
}

const fields = {
  email: {
    ...email
  },
  password: {
    ...password,
    showTooltipHints: false
  }
};

export interface LoginProps {
  loading: boolean;
  responseData: {
    status: string;
    invalidFields: string[];
  };
  match:any;
  location:any;
  history:any;

  showModal:Function;

  onSubmitHandler: (data: {
    password: string;
    confirmPassword: string;
    rcode: string;
    __RequestVerificationToken: string;
  }) => void;
  //showModal: (modal: AlertModal) => void;
};


class Login extends React.Component<LoginProps>  {
  form: IForm;

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { responseData: data, loading } = nextProps;
    if (data) {
      if (data.result == "error"){
        const message = translate('guest', 'login.invalidCredentials');
        if (this.props.loading == nextProps.loading){
          this.props.showModal({
            name: 'LOGIN_ALERT',
            type: 'alert',
            alertType: 'warning',
            message,
          });
        }
        this.form.setState({ inProgress: false });
      }
      else{
        //this.form.setState({ inProgress: false });
        window.location.assign("/");
      }
    }
    if (!loading) {
      //this.form.setState({ inProgress: false });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.url === '/login/attempt' && /invalid=true/.test(this.props.location.search)) {
      if (!window.loginAttempt) {
        const message = translate('guest', 'login.invalidCredentials');
        
        this.props.showModal({
          name: 'LOGIN_ALERT',
          type: 'alert',
          alertType: 'warning',
          message,
        });
      } else {
        
        fields.email.value = window.loginAttempt.email;
        fields.password.value = window.loginAttempt.password;
      }
    }
  }

  componentDidMount() {
    if (this.props.match.url === '/login/attempt' && window.loginAttempt) {
      this.form.handleSubmit();
    } else {
      this.form.reset();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.match === nextProps.match && this.props.location === nextProps.location && this.props.history === nextProps.history) {
      return false;
    }
    return true;
  }

  onSubmit({ email, password }) {
    // const hiddenForm = $('#hidden-login-form');
    // hiddenForm.find('input[name=user]').val(email);
    // hiddenForm.find('input[name=password]').val(password);
    // hiddenForm.get(0).submit();
    
    //console.log(e)

    //document.querySelector('#hidden-login-form input[name=user]').value = email;
    //let antiforgeryToken = document.querySelector('input[name=__RequestVerificationToken]').value


    /*const hiddenForm = document.getElementById('hidden-login-form');
    document.querySelector('#hidden-login-form input[name=UserEmail]').value = email;
    document.querySelector('#hidden-login-form input[name=LoginPassword]').value = password;
    document.querySelector('#hidden-login-form input[name=__RequestVerificationToken]').value = requestToken;
    hiddenForm.submit();*/
    const body:any = {
      UserEmail: email,
      LoginPassword: password,
      __RequestVerificationToken:requestToken,
    };
    this.props.onSubmitHandler(body);

    //this.form.setState({ inProgress: true });
  }

  render() {
    return (<div className="form-page">
      <h1 className="form-page__title">
        {translate('guest', 'login.title')}
      </h1>
      <Form
        fields={fields}
        buttonLabel={t('button.login')}
        onSubmitForm={this.onSubmit}
        afterButton={<Link
          className="form-page__forgotten-password-link anim-underline"
          to="/forgotten-password"
        >{t('form.forgottenPassword')}
        </Link>}
        ref={(instance) => { this.form = instance; }}
      />
      <div className="form-page__bottom-link">
        <Link
          className="anim-underline"
          to="/registration"
        >{translate('guest', 'registration.title')}
        </Link>
      </div>
      <form id="hidden-login-form" style={{ display: 'none' }} action="/Home/Login" method="post">
        <input name="UserEmail" type="text" />
        <input name="LoginPassword" type="password" />
        <input type="hidden" name="__RequestVerificationToken" value={requestToken} />
      </form>
    </div>);
  }
}

/*Login.propTypes = {
  match: shape({
    url: string,
    params: shape({
      queryString: string,
    }),
  }),
  location: shape({
    pathname: string,
    search: string,
  }),
  history: shape({
    action: string,
  }),
  showModal: func.isRequired,
};

Login.defaultProps = {
  match: null,
  location: null,
  history: {},
};*/

const mapDispatchToProps = {
  onSubmitHandler: ac.login,
  showModal,
};

const mapStateToProps = state => ({
  loading: isLoading(state),
  responseData: getData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

