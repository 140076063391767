import { actionTypes as at } from '../actions';
import Types from 'Types';
import { AnyAction } from 'redux';

export const initialState = {
    data: null,
    loading: false,
};

export const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        case at.IS_ACCOUNT_DEACTIVATED:
            return {
                data: null,
                loading: true,
            }
        case at.IS_ACCOUNT_DEACTIVATED_ERROR:
            return {
                ...state,
                loading: false,
                data: payload.data,
            }
        case at.IS_ACCOUNT_DEACTIVATED_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
            }
        default:
            return state;
    }
};


export default reducer;

export const getData = ({ inactiveAccount: { isAccountDeactivated } }: Types.StoreState) => isAccountDeactivated.data;

export const isLoading = ({ inactiveAccount: { isAccountDeactivated } }: Types.StoreState) => isAccountDeactivated.loading;
