import * as React from "react";
import { Link } from "react-router-dom";
import "./styles/NotFound.scss";
import Button from "../components/Button";
import { translate } from "../../l10n";

const t = (str: string, context = "common") => translate(context, str);


const NotFound = () => (
    <div className="not-found">
        <span className="not-found__text">4</span>
        <span className="not-found__text"><i className="icon-download" /></span>
        <span className="not-found__text">4</span>
        <h1>{t("notFound.title")}</h1>
        <h2>{t("notFound.text")}</h2>
        <ul className="not-found__links">
            <li>
                <Link className="anim-underline" to="/">{t("home")}</Link>
            </li>
            <li>
                <Link className="anim-underline" to="/login">{t("button.login")}</Link>
            </li>
            <li>
                <Link className="anim-underline" to="/contact-us">{t("contactUs")}</Link>
            </li>
            <li>
                <Link className="anim-underline" to="/terms">{t("footer.terms")}</Link>
            </li>
            <li>
                <Link className="anim-underline" to="/service-level">{t("footer.serviceLevel")}</Link>
            </li>
            <li>
                <Link className="anim-underline" to="/privacy">{t("footer.privacy")}</Link>
            </li>
        </ul>
        <Button type="primary" className="mt-20" onClick={() => { window.history.back(); }}>{t("button.goBack")}</Button>
    </div>
);

export default NotFound;
