import { actionTypes as at } from '../actions';
import Types from 'Types';
import { AnyAction } from 'redux';

export const initialState = {
  data: null,
  loading: false,
};

export const reducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case at.SEND_ACTIVATION:
      return {
        data: null,
        loading: true,
      }
    case at.SEND_ACTIVATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case at.SEND_ACTIVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
      }
    default:
      return state;
  }
};


export default reducer;

export const getData = ({ inactiveAccount: { acountActivation } }: Types.StoreState) => acountActivation.data;

export const isLoading = ({ inactiveAccount: { acountActivation } }: Types.StoreState) => acountActivation.loading;
