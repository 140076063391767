import * as React from 'react';
import './styles/Layout.scss';
import Header from './HeaderGuest';
import Footer from './Footer';
import ErrorBoundary from '../../../shared/components/ErrorBoundary';
import { History, Location } from 'history';

export interface LayoutPros {
  children: React.ReactNode;
  match: {
    url: string;
  },
  history: History;
  location: Location;
};

function Layout({ match, children, history, location }: LayoutPros) {
  return (<div>
    <Header history={history} />
    <div id="app-content">
      <div className="page">
        <ErrorBoundary location={location}>
          {children}
        </ErrorBoundary>
      </div>
    </div>
    <Footer url={match.url} />
  </div>);
}

export default Layout;
