import keyMirror from "key-mirror";
import { createStandardAction } from "typesafe-actions";
//import { ConfigurationChanges, VmInstanceDetails } from './types'

export const actionTypes = keyMirror({

  WS_RDP_CONNECT: null,
  WS_RDP_CONNECT_ERROR: null,
  WS_RDP_CONNECT_SUCCESS: null,
  
  WS_RDP_MESSAGE: null,
  WS_RDP_SEND_MESSAGE: null,
  WS_RDP_SEND_SPECIAL_KEY: null,
  WS_RDP_OPEN: null,
  WS_RDP_RESET: null,
  WS_RDP_CLOSE: null,
  WS_RDP_ERROR: null,
  WS_RDP_DISCONNECT: null,
  WS_RDP_PROCESS_DATA: null,

  WS_SET_SID: null,
  WS_WRITE_DEBUG_CONSOLE: null,

  WS_ADD_EVENT_LISTENERS: null,
  WS_REMOVE_EVENT_LISTENERS: null,

});

const at = actionTypes;


export const actionCreators = {
  createVmRDPSocket: createStandardAction(at.WS_RDP_CONNECT)<{ vmId: string, accountId:string}>(),
  closeVmRDPSocket: createStandardAction(at.WS_RDP_DISCONNECT)<void>(),
  sendRDPMessage: createStandardAction(at.WS_RDP_SEND_MESSAGE)<{message:ArrayBuffer}>(),
  sendSpecialKey: createStandardAction(at.WS_RDP_SEND_SPECIAL_KEY)<{keyCombination:"AltCtrlDel"|"Lock"}>(),

  addIteractionEventListeners: createStandardAction(at.WS_ADD_EVENT_LISTENERS)<void>(),
  removeIteractionEventListeners: createStandardAction(at.WS_REMOVE_EVENT_LISTENERS)<void>(),

  vmRDPSocketOpen: createStandardAction(at.WS_RDP_OPEN)<{event:Event}>(),
  vmRDPSocketClose: createStandardAction(at.WS_RDP_CLOSE)<{event:Event}>(),
  vmRDPSocketError: createStandardAction(at.WS_RDP_ERROR)<{event:Event}>(),
  vmRDPSocketMessage: createStandardAction(at.WS_RDP_MESSAGE)<{event:MessageEvent}>(),
  //socketOpen(e:Event) = {},
}


//export const { rdpConsole } = actionCreators;