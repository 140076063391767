import { ActionType } from 'typesafe-actions';
import { actionTypes as at, actionCreators } from "./actions";
import Types from 'Types';

export type Action = ActionType<typeof actionCreators>;

interface State {
  showCookieConsent: boolean,
  showPersonalDataPopup: boolean,
}

export const initialState = {
  showCookieConsent: false,
  showPersonalDataPopup: false,
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case at.SHOW_COOKIE_CONSENT:
      return {
        ...state,
        showCookieConsent: true,
      }
    case at.SHOW_PERSONAL_DATA_POPUP:
      return {
        ...state,
        showPersonalDataPopup: true,
      }
    default:
      return state;
  }
};

export const getData = ({ common }: Types.StoreState) => common;

export default reducer;
