import * as React from 'react';
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import './styles/Tooltip.scss';

export type TooltipProps = {

}

class Tooltip extends React.PureComponent<any> {

  render() {
    return (
      <RCTooltip
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        mouseEnterDelay={0.3}
        mouseLeaveDelay={0.1}
        destroyTooltipOnHide
        overlay={null}
        {...this.props}
      />
    );
  }
}

export default Tooltip;
