import uuid from 'uuid/v1';
import Types from 'Types';
import { ActionType } from 'typesafe-actions';
import { actionTypes as at, actionCreators } from "./actions";

export type Action = ActionType<typeof actionCreators>;

export const initialState = [];

const reducer = (state = initialState, { type, payload }: Action) => {
    switch (type) {
        case at.SHOW_MODAL:
            return state.concat({
                id: uuid(),
                ...payload
            });
        case at.HIDE_MODAL:
            return state.filter(({ id }) => id !== payload.id);
        default:
            return state;
    }
};

export const getModals = ({ modals }: Types.StoreState) => modals;

export default reducer

// alert modal 
// store.dispatch({
//     type: 'SHOW_MODAL',
//     payload: {
//         type: "alert",
//         alertType: "success",
//         message: 'dsadsd'
//     },
//     onClose: () => {
//         console.log();
//     }
// })


// confirm modal

// store.dispatch({
//     type: 'SHOW_MODAL',
//     payload: {
//         type: "confirm",
//         message: 'payload.message',
//     },
//     onClose: (confirmed) => {
//         console.log(confirmed);
//     }
// })

// custom modal
// store.dispatch({
//     type: 'SHOW_MODAL',
//     payload: {
//          type: 'custom',
//          content: ()=> 'tetst',
//     }
//    })