import * as React from "react";
import classNames from "classnames";
import "../../../../shared/components/styles/ModalDialog.scss";
import Button from "../../../components/Button";
import ModalIcon from './ModalIcon'
import { translate as t } from "../../../../l10n";
import { BaseModal, AlertType, ModalType } from '../types';

function setMarginTop(style: React.CSSProperties, options: BaseModal['options']) {
  const height = window.screen.availHeight;
  if (height > 800) {
    if (options.large) {
      style.marginTop = '150px';
      style.width = '680px';
    } else {
      style.marginTop = `${height / 4}px`;
    }
  }
  return style;
}

export interface IButton {
  text: string;
  type: "primary" | "black" | "info";
  onClick: () => void;
}

export interface ModalDialogProps {
  type: ModalType;
  title?: string;
  iconClassName?: string;
  alertType?: AlertType | "confirm";
  onClose?: (param?: object) => void;
  message?: string;
  buttons?: IButton[];
  content?: (b: object) => JSX.Element;
  options?: BaseModal['options'];
}

interface State {
  disappear: boolean;
}

const setFilterStyle = (selector: string, filter: string) => {
  const $elements = document.querySelectorAll(selector);
  $elements.forEach((element: HTMLElement) => {
    element.style.filter = filter;
  })
};

class ModalDialog extends React.Component<ModalDialogProps, State> {
  readonly state: State = {
    disappear: false,
  };

  static defaultProps = {
    iconClassName: "icon-info-sign",
    title: null,
    message: null,
    alertType: "warning",
    content: null,
    buttons: [{
      type: 'primary',
      text: t("common", "button.ok"),
    }],
    options: {
      innerHTML: false,
      large: false,
    }
  };
  private modalStyle: React.CSSProperties;
  constructor(props: ModalDialogProps) {
    super(props);
    this.modalStyle = setMarginTop({}, props.options);
  }

  handleAction = (action) => () => {
    this.setState(({ disappear }) => ({ disappear: !disappear }));
    setTimeout(() => {
      action();
    }, 600); // 0.5s is animation duration
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ disappear: false });
  }

  componentDidMount() {
    // disable scrolling when modal is shown
    document.body.style.overflow = "hidden";
    // blur the page content behind the modal 
    // ps.. selecting the root element gives the weird side effect so use different selector
    setFilterStyle(".header, .page, .app-navigation", 'blur(2px)');
    window.onpopstate = () => {

      this.setState(({ disappear }) => ({ disappear: !disappear }));
      setTimeout(() => {
      }, 600); // 0.5s is animation duration
      this.handleAction(this.props.onClose())
      document.body.style.overflow = "auto";
      setFilterStyle(".header, .page, .app-navigation", '');
    }

  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
    setFilterStyle(".header, .page, .app-navigation", '');
  }

  renderHeader() {
    const { iconClassName, title } = this.props;
    return (<div className="rc-dialog-header">
      <div className="rc-dialog-title">
        <i className={iconClassName} />
        <span className="dialog-title-text">{title}</span>
      </div>
    </div>);
  }

  renderContent() {
    const { alertType, message, options } = this.props;
    let messageDisplay = message;
    if (message != null && message['Content'] != undefined) {
      const regexMessage = /["\[\]"]/gm;
      messageDisplay = message['Content'].replace(regexMessage, "");
    }
    return (
      <div className="dialog__content">
        {alertType == "withoutIcon" ? <span></span> : <ModalIcon type={alertType} />}
        {/* {commonTypesOferror.includes(type) ? <div className="alert-icon">
            <span className={ringClass} />
            <span className={iconClass} />
          </div> : <div></div>} */}
        <div className="dialog__message">
          {options.innerHTML ? <span dangerouslySetInnerHTML={{ __html: messageDisplay }} /> : messageDisplay}
        </div>
      </div>
    );
  }

  renderFooter() {
    const { buttons, onClose } = this.props;
    return (<div className="rc-dialog-footer">
      {
        buttons.map(({ text, onClick, ...buttonProps }) => (
          <Button
            key={text}
            {...buttonProps}
            onClick={onClick ? this.handleAction(onClick) : this.handleAction(onClose)}
          >
            <span>{text}</span>
          </Button>
        ))
      }
    </div>);
  }

  render() {
    const { disappear } = this.state;
    const { type, content, onClose } = this.props;
    const isCustom = type === 'custom';
    const dialogClass = classNames("rc-dialog", {
      "slide-in-top": !disappear,
      "slide-out-top": disappear,
      "rc-dialog--custom-content": isCustom,
    });
    return (
      <div className="rc-dialog-wrap" role="dialog" >
        <div role="document" className={dialogClass} style={this.modalStyle} >
          <div className="rc-dialog-content">
            <button className="rc-dialog-close" onClick={this.handleAction(onClose)}>
              <span className="rc-dialog-close-x" />
            </button>
            {this.renderHeader()}
            <div className="rc-dialog-body">
              {isCustom ? content({ onCloseModal: this.handleAction(onClose) }) : this.renderContent()}
            </div>
            {isCustom ? null : this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalDialog;
