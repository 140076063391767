function getDataset(str: string) {
  const el = document.getElementById(str);
  if (el) {
    return document.getElementById(str).dataset;
  }
  console.error('Config data is not provided');
  return {};
}
const pdfs = getDataset('pdfs');
const clients = getDataset('clients');
const addins = getDataset('addins');
const endpoints = getDataset('endpoints');

const config = {
  paths: {
    pdfs: {
      terms: pdfs.terms,
      privacy: pdfs.privacy,
      serviceLevel: pdfs.servicelevel,
    },
    clients,
    addins,
  },
  endpoints: {
    onlyOffice: endpoints.onlyoffice,
  },
};

export const MOBILE_WIDTH = 880;

export default config;
