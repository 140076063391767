import * as React from "react";
import { connect } from "react-redux";
import Types from "Types";
import { Link } from "react-router-dom";
import "../styles/CloudCalculator.scss";
import { OS, MetricId, MetricName, VmConfig } from "./types";
import InputRange from "./InputRange";
import CloudRadioGroup from "./CloudRadioGroup";
import { isLoading, getData, getPrice } from "../../reducer";
import { actionCreators as ac } from "../../actions";
import { translate } from "../../../../l10n";
import ServiceReliability from "./ServiceReliability";

const t = (str: string, context = "guest") => translate(context, str);

const defaultConfiguration = {
  linux: {
    cpu: 1,
    ram: 1024,
    hdd: 30,
  },
  windows: {
    cpu: 2,
    ram: 2048,
    hdd: 50,
  },
};

interface Metric {
  id: MetricId;
  label: MetricName;
  steps: {
    value: number;
    label: string;
  }[];
  value: number;
}

export interface CloudCalculatorProps {
  loading: boolean;
  metrics: Metric[];
  price: string;
  updatePrice: (config: State["config"]) => void;
  componentReady: () => void;
}

interface State {
  os: OS | null;
  config: VmConfig;
}

class CloudCalculator extends React.Component<CloudCalculatorProps, State> {
  private rangeChange: boolean = false;
  readonly state: State = {
    os: null,
    config: {
      cpu: 0,
      ram: 0,
      hdd: 0,
    },
  };

  UNSAFE_componentWillMount() {
    this.props.componentReady();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.metrics !== this.props.metrics) {
      const config = nextProps.metrics.reduce(
        (acc: { id: number }, cv: Metric) => {
          acc[cv.id] = cv.value;
          return acc;
        },
        {}
      );
      this.setState({ config: config });
    }
  }

  onChangeOperatingSystem = (os: OS) => {
    if (!this.rangeChange) {
      const config = defaultConfiguration[os];
      this.setState(
        () => ({ os, config }),
        () => {
          this.props.updatePrice(config);
        }
      );
    }
    this.setState({ os });
  };

  onChangeMetrics = ({ id, value }: { id: MetricId; value: number }) => {
    var newConfig = {
      [id]: value,
    };
    const config = { ...this.state.config, ...newConfig };
    this.rangeChange = true;
    this.setState(
      () => ({ config }),
      () => {
        this.props.updatePrice(config);
      }
    );
  };

  render() {
    const { os, config } = this.state;
    const { loading, metrics, price } = this.props;
    return (
      <div className="calculator">
        <h1>{t("cloudCalculator.infrastructureIAAS")}</h1>
        <div className="cloud-calculator">
          <div className="cloud-calculator__content">
            {loading && (
              <span className="cloud-calculator__loading">
                <i className="icon-spinner8"></i>
              </span>
            )}
            {metrics.map(({ id, label, steps, value }) => (
              <InputRange
                key={id}
                id={id as MetricId}
                label={label as MetricName}
                steps={steps}
                value={config[id] || value}
                onChange={this.onChangeMetrics}
              />
            ))}
          </div>
          <CloudRadioGroup
            selectedOS={os}
            onChange={this.onChangeOperatingSystem}
          />
        </div>
        <p className="mt-30">{price}</p>
        <Link
          className="btn mt-30 btn--rounded btn--gradient-darkblue"
          to="/registration"
          style={{ lineHeight: "1.5em" }}
        >
          <span>
            <i className="fullscreen-slider__icon-add-user icon-add-user" />
            <span className="registration-button-text text-uppercase">
              {t("fullScreenSlider.registerNow")}
            </span>
          </span>
        </Link>
        <h2 className="mt-30">
          {t("cloudCalculator.freeLicense")}{" "}
          <span className="text-important">
            {t("cloudCalculator.costFree")}
          </span>
          .
        </h2>
        <ServiceReliability />
      </div>
    );
  }
}

const mapStateToProps = (state: Types.StoreState) => ({
  loading: isLoading(state),
  metrics: getData(state),
  price: getPrice(state),
});

const mapDispatchToProps = {
  componentReady: ac.componentReady,
  updatePrice: ac.updatePrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloudCalculator);
