import * as React from 'react';
import Header from '../../../shared/components/Header';
import HeaderLinks from '../../../shared/components/HeaderLinks';
import DropdownMenu from '../../../shared/components/DropdownMenu';
import LoginForm from './LoginForm';


const HeaderGuest = props => (
    <Header>
        <HeaderLinks />
        <LoginForm {...props} />
        <DropdownMenu
          component={LoginForm}
          containerHeight={230}
          componentProps={{ mobile: true, ...props }}
          contentClassName="mobile-login-content"
        />
    </Header>
);

export default HeaderGuest;
