import keyMirror from "key-mirror";
import { createStandardAction } from "typesafe-actions";
import { buildQueryString } from "../../scripts/utils/string";
import { toFormData } from "../../shared/tools/dataTools";

export const actionTypes = keyMirror({
  EMAIL_CONFIRMATION: null,
  EMAIL_CONFIRMATION_ERROR: null,
  EMAIL_CONFIRMATION_SUCCESS: null,

  EMAIL_CONFIRMATION_RESET_CODE: null,
  EMAIL_CONFIRMATION_RESET_CODE_ERROR: null,
  EMAIL_CONFIRMATION_RESET_CODE_SUCCESS: null,
});

const at = actionTypes;

export const actionCreators = {
  emailConfirmation: createStandardAction(at.EMAIL_CONFIRMATION).map(
    (payload: { regcode: string }) => ({
      payload,
      meta: {
        request: {
          url: `/home/emailconfirmation?${buildQueryString(payload)}`,
        },
      }
    })
  ),
  resetEmailConfirmation: createStandardAction(at.EMAIL_CONFIRMATION_RESET_CODE).map(
    (payload: any) => ({
      payload,
      meta: {
        request: {
          url: '/home/ResetVerificationCode',
          method: 'POST',
          body: toFormData(payload),
          mode: "withToken"
        },
      }
    })
  )
}


