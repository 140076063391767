export function isMac() {
  return navigator.platform.indexOf('Mac') > -1;
}

let _browser = null;
export function getBrowser() {
  if (_browser) {
    return _browser;
  }
  const ua = navigator.userAgent;

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    _browser = {
      name: 'edge',
      version: parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10),
    };
    return _browser;
  }


  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) { return { name: 'Opera', version: tem[1] }; }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); } //eslint-disable-line
  _browser = {
    name: M[0].toLowerCase(),
    version: M[1],
  };
  return _browser;
}


