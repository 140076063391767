import * as React from 'react';
import { Link } from 'react-router-dom';
import './styles/FullscreenSlider.scss';
import ImageSlider from './ImageSlider';
import * as slide1 from '../../../assets/images/slide1.jpg';
import * as slide2 from '../../../assets/images/slide2.jpg';
import * as slide3 from '../../../assets/images/slide3.jpg';
import { translate } from '../../../l10n';

const t = (str, context = 'guest') => translate(context, str);

const settings = {
  fade: true,
  useCSS: true,
  lazyLoad: true,
  dots: true,
  arrows: false,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  // pauseOnHover: true,
  autoplaySpeed: 9000,
};

interface State {
    currentSlideIndex: number
}

class FullscreenSlider extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentSlideIndex: 0,
    };
    this.beforeSlideChange = this.beforeSlideChange.bind(this);
  }

  beforeSlideChange(oldIndex, newIndex) {
    this.setState({ currentSlideIndex: newIndex });
  }

  renderSlideText() {
    let slideName = null;
    switch (this.state.currentSlideIndex) {
      case 1:
        slideName = 'slideTwo';
        break;
      case 2:
        slideName = 'slideThree';
        break;
      case 0:
      default:
        slideName = 'slideOne';
    }

    return (<React.Fragment>
        <h1>{t(`fullScreenSlider.${slideName}.title`)}</h1>
        <p>{t(`fullScreenSlider.${slideName}.text`)}</p>
    </React.Fragment>);
  }

  render() {
    const sliderSettings = {
      ...settings,
      beforeChange: this.beforeSlideChange,
    };
    return (
        <div className="fullscreen-slider">
            <div className="fullscreen-slider__container">
                <ImageSlider {...sliderSettings}>
                    <div className="fullscreen-slider__image-content">
                        <img className="fullscreen-slider__image" src={slide1} alt="Slide 1" />
                    </div>
                    <div className="fullscreen-slider__image-content">
                        <img className="fullscreen-slider__image" src={slide2} alt="Slide 2" />
                    </div>
                    <div className="fullscreen-slider__image-content">
                        <img className="fullscreen-slider__image" src={slide3} alt="Slide 1" />
                    </div>
                </ImageSlider>
            </div>
            <div className="fullscreen-slider__text-content">
                {this.renderSlideText()}
                <Link className="btn mt-20 btn--rounded btn--gradient-darkblue" to="/registration" style={{ lineHeight: '1.5em' }}>
                    <span>
                        <i className="fullscreen-slider__icon-add-user icon-add-user" />
                        <span className="registration-button-text">{t('fullScreenSlider.registrationButton')}</span>
                    </span>
                </Link>
            </div>
            <i className="fullscreen-slider__icon-down icon-menu-down" />
        </div>
    );
  }
}

export default FullscreenSlider;

