import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bool, func, shape } from 'prop-types';
import classNames from 'classnames';
import './styles/LoginForm.scss';
import Input from '../../../shared/components/Input';
import Button from '../../../shared/components/Button';
import { validateField } from '../../../shared/components/Form/js/formValidation';
import { translate } from '../../../l10n';
import { requestToken } from '../../../scripts/common/db';
import { actionCreators as ac } from '../../submit-form/actions';
import { isLoading, getData } from '../../submit-form/reducer/login';
import { Link } from 'react-router-dom';

const t = (str, context = 'common') => translate(context, str);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { responseData: data, loading } = nextProps;
    if (data) {
      if (data.result == "error") {
        //const message = translate('guest', 'login.invalidCredentials');
        //const email = this.emailInput.value;
        //const password = this.passwordInput.value;
        //window.loginAttempt = {
        //  email,
        //  password,
        //};

        this.props.history.push('/login/attempt?invalid=true');
        //this.form.setState({ inProgress: false });
      }
      else {
        //this.form.setState({ inProgress: false });
        window.location.assign("/");
      }
    }
    if (!loading) {
      //this.form.setState({ inProgress: false });
    }
  }

  onSubmit(event) {
    event.preventDefault();
    // if input fields are hidden only button is show redirect to login page
    if (!this.props.mobile && window.innerWidth <= 1300) {
      this.props.history.push('/login');
      return;
    }

    if (this.props.hideDropdown) {
      this.props.hideDropdown(); // hide dropdown menu
    }

    // if fields are valid try to login
    // else pass props to login page
    const email = this.emailInput.value;
    const password = this.passwordInput.value;
    if (!validateField('email', email) && !validateField('password', password)) {
      this.setState({ inProgress: true });
      //this.form.submit();
      const body = {
        UserEmail: email,
        LoginPassword: password,
        __RequestVerificationToken: requestToken,
      };
      this.props.onSubmitHandler(body);
      return;
    }

    window.loginAttempt = {
      email,
      password,
    };
    this.props.history.push('/login/attempt?invalid=true');
  }

  render() {
    if (/login|register-guest/.test(window.location)) {
      return null;
    }
    const formClass = classNames({
      'login-form': !this.props.mobile,
      'login-form--mobile': this.props.mobile,
    });

    const btnClass = classNames({
      'btn--spin': this.state.inProgress,
    });

    return (<div className={formClass}>
      <form className="form" action="/Home/Login" method="post" ref={(el) => { this.form = el; }}>
        {this.props.mobile ? <label htmlFor="email">{t('form.email')}</label> : null}
        <Input
          name="UserEmail"
          type="text"
          placeholder={t('form.email')}
          inputRef={(input) => { this.emailInput = input; }}
        />
        {this.props.mobile ? <label htmlFor="password">{t('form.password')}</label> : null}
        <Input
          type="password"
          name="LoginPassword"
          placeholder={t('form.password')}
          inputRef={(input) => { this.passwordInput = input; }}
        />
        <input type="hidden" name="__RequestVerificationToken" value={requestToken} />
        <span className="button-forgotten-password">
          <Button className={btnClass} gradient="light" rounded disabled={this.state.inProgress} onClick={this.onSubmit}>
            <span>
              <i className="icon-arrow icon-arrow-right" />
              <span className="text-uppercase">{t('button.login')}</span>
              <i className="spinner icon-spinner2" />
            </span>
          </Button>
          <Link
            className="forgotten-password-login under-button"
            to="/forgotten-password"
          >{t('form.forgottenPassword')}
          </Link>
        </span>
      </form>
    </div>);
  }
}

LoginForm.propTypes = {
  mobile: bool,
  hideDropdown: func,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

LoginForm.defaultProps = {
  mobile: false,
  hideDropdown: null,
};

const mapDispatchToProps = {
  onSubmitHandler: ac.login,
};

const mapStateToProps = state => ({
  loading: isLoading(state),
  responseData: getData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
//export default LoginForm;
