import * as React from 'react';

interface ReactChildNode {
  type?: any;
}

export interface RadioGroupProps {
  name: string,
  children: React.ReactNode;
  style?: React.CSSProperties;
};

class RadioGroup extends React.Component<RadioGroupProps> {
  constructor(props: RadioGroupProps) {
    super(props);
    this.renderChildren = this.renderChildren.bind(this);
  }

  renderChildren() {
    return React.Children.map(this.props.children, (child: React.ReactChild & ReactChildNode) => {
      if (child.type.displayName === 'RadioButton') {
        return React.cloneElement(child as React.ReactElement<any>, {
          name: this.props.name,
        });
      }
      return child;
    });
  }

  render() {
    const { name, children, ...rest } = this.props;
    return (<div {...rest}>
      {this.renderChildren()}
    </div>);
  }
}

export default RadioGroup;
