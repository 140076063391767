import * as ePayLogo from "../../assets/images/epaybg.png";
import * as stripeLogo from "../../assets/images/stripe-cards.png";

export function toFormData(object) {
  const formData = new FormData();
  for (const key in object) {
    if (key !== 'attachments') {
      formData.append(key, object[key]);
    }
  }
  if (object.attachments) {
    object.attachments.forEach((file) => {
      formData.append('files[]', file, file.name);
    });
  }

  return formData;
}


export const paymentProviderImages = {
  epay: ePayLogo,
  payPal:
    "https://www.paypalobjects.com/digitalassets/c/website/marketing/na/us/logo-center/9_bdg_secured_by_pp_2line.png", //'https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg',
  stripe: stripeLogo, //StripeLogo,
};
