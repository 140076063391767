let path = "/";

const env = process.env.FORCE_NODE_ENV;
if (env === undefined) {
  path = "/v2";
} else if (env === "staging") {
  path = "https://stage.oblak.bg";
} else if (env === "production") {
  path = "https://oblak.bg";
} else if (env === "local-development") {
  path = "http://localhost";
}
else if (env === "dev-oblak") {
  path = "https://dev.oblak.bg"
}
export default path;
