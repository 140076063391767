import modals from '../shared/sagas/modals/reducer';
import common from '../shared/sagas/common/reducer';
import submitForm from './submit-form/reducer';
import inactiveAccount from './inactive-account/reducer/index';
import confirmRegistration from './confirm-registration/reducer';
import cloudCalculator from './index-guest/reducer';
import { reducer as sharedConsole } from '../shared/pages/SharedConsole/reducer';
import { reducer as vmConsoleSocketConnection } from '../shared/components/ConsoleScreen/reducer';

export default {
  modals,
  common,
  cloudCalculator,
  submitForm,
  inactiveAccount,
  confirmRegistration,
  sharedConsole,
  vmConsoleSocketConnection,
};
