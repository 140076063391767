import { call, put, takeEvery } from 'redux-saga/effects';
import { createStandardAction } from "typesafe-actions";
import { appFetch as fetch, Request } from './fetch/fetch';
import { showModal } from "../modals/actions";
import { translate as t } from "../../../l10n";

export type Meta = {
  request: Request;
  suppressError?: boolean;
}


export const fetchError = (type, error) =>
  createStandardAction(`${type}_ERROR`).map(
    (payload: object) => ({
      payload,
    })
  )(error);


export const fetchSuccess = (type, response) =>
  createStandardAction(`${type}_SUCCESS`).map(
    (payload: object) => ({
      payload,
    })
  )(response)

export function* executeFetchSaga({ type, meta: { request, suppressError } }: { type: string, meta: Meta }) {
  const { error, response } = yield call(fetch, request);
  if (error instanceof TypeError) {
    yield put(fetchError(type, error.message));
    return
  }

  if (error) {
    const { error: status, response: res } = error;
    let result = undefined;
    const contentType = res && res.headers.get('Content-Type') && res.headers.get('Content-Type').split(';')[0];
    if (contentType === 'application/json') {
      result = yield res.json();
    }

    if (status === 401) {
      yield put(showModal({
        type: "alert",
        alertType: "warning",
        message: t('common', 'request.expiredSessionMessage'),
      }));
    } else {
      if (result.message == undefined || result.text == undefined) {
        result.message = t('common', 'request.unexpectedErrorMessage');
        result.text = t('common', 'request.unexpectedErrorMessage');
      }
      if (!suppressError) {
        yield put(showModal({
          type: "alert",
          alertType: "warning",
          message: result ? (result.message || result.text || result.Message) : t('common', 'request.unexpectedErrorMessage'),
          options: {
            innerHTML: true,
          }
        }));
      }
    }
    yield put(fetchError(type, result || res));
    return;
  }

  let res = null;
  if (!response.Status) {
    res = response;
  } else {
    if (response.Status.toLocaleLowerCase() === "ok") {
      res = response.Data;
    } else {
      yield put(fetchError(type, response));
      if (!suppressError) {
        yield put(showModal({
          type: "alert",
          alertType: "warning",
          message: response.Message || t('common', 'request.unexpectedErrorMessage'),
          options: {
            innerHTML: true,
          }
        }));
      }
      return;
    }
  }
  // console.log(type, res);
  yield put(fetchSuccess(type, res));
}

export const sagas = function* () {
  yield takeEvery(
    action => !!action.meta && action.meta.request,
    executeFetchSaga,
  );
};
