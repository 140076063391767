import * as React from 'react';
import './styles/Spinner.scss';

export interface SpinnerProps {
  small: boolean;
  top?: number;
  left?: number;
  right?: number;
};

function Spinner({ small, top, left, right, ...rest }: SpinnerProps) {
  const props = {
    className: null,
    style: {}
  };
  if (small) {
    props.className = 'spinner--small';
  }

  const style: React.CSSProperties = {};
  if (top) {
    style.marginTop = `${top}px`;
  }

  if (left) {
    style.marginLeft = `${left}px`;
  }

  if (right) {
    style.marginRight = `${right}px`;
  }

  props.style = style;
  return <div id="spinner" {...props} {...rest} />;
}

Spinner.defaultProps = {
  small: false,
  top: 0,
  left: 0,
};

export default Spinner;
