import * as React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { History } from 'history';
import { Route, Switch } from 'react-router-dom';
import ScrollToTop from '../shared/routes/ScrollToTop';
import asyncComponent from '../shared/routes/asyncComponent';
import RouteWithLayout from '../shared/routes/RouteWithLayout';
import ModalRoot from '../shared/sagas/modals/components/ModalRoot';
import { Terms, ContactUs, ServiceLevel, PrivacyPolicy, CookiePolicy, PersonalDataPolicy, NotFound, EmailMarketing, LeadsMarketing } from '../shared/routes';

import GuestLayout from './layout';
import IndexGuest from './index-guest/components/IndexGuest';
import Login from './login/components/Login';
import Registration from './registration/components/Registration';
import InactiveAccount from './inactive-account/components/InactiveAccount';
const SharedConsole = asyncComponent(() => import(/* webpackChunkName: "shared-console" */'../shared/pages/SharedConsole'));
const ConfirmRegistration = asyncComponent(() => import(/* webpackChunkName: "confirm-registration" */'./confirm-registration/components/ConfirmRegistration'));
const ResetPassword = asyncComponent(() => import(/* webpackChunkName: "reset-password" */'./reset-password/components/ResetPassword'));
const ForgottenPassword = asyncComponent(() => import(/* webpackChunkName: "forgotten-password" */'./forgotten-password/components/ForgottenPassword'));
//const Login = asyncComponent(() => import(/* webpackChunkName: "login" */'./login/components/Login'));

export interface AppProps {
    //store: Store<Types.StoreState, any>;
    store: Store;
    history: History;
};
const App = ({ store, history }: AppProps) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ScrollToTop>
                <Switch>
                    <RouteWithLayout layout={GuestLayout} path="/" exact component={IndexGuest} />
                    <RouteWithLayout layout={GuestLayout} path="/login/:queryString?" component={Login} />
                    <RouteWithLayout layout={GuestLayout} path="/registration" component={Registration} />
                    <RouteWithLayout layout={GuestLayout} path="/home/confirmation/:regcode?" component={ConfirmRegistration} />
                    <RouteWithLayout layout={GuestLayout} path="/inactive-account/:queryString?" component={InactiveAccount} />
                    <RouteWithLayout layout={GuestLayout} path="/forgotten-password" component={ForgottenPassword} />
                    <RouteWithLayout layout={GuestLayout} path="/home/passwordrecover" component={ResetPassword} />
                    <RouteWithLayout layout={GuestLayout} path="/console" component={SharedConsole} />
                    <RouteWithLayout layout={GuestLayout} path="/contact-us" component={ContactUs} />
                    <RouteWithLayout layout={GuestLayout} path="/terms" component={Terms} />
                    <RouteWithLayout layout={GuestLayout} path="/privacy" component={PrivacyPolicy} />
                    <RouteWithLayout layout={GuestLayout} path="/service-level" component={ServiceLevel} />
                    <RouteWithLayout layout={GuestLayout} path="/cookie-policy" component={CookiePolicy} />
                    <RouteWithLayout layout={GuestLayout} path="/personal-policy" component={PersonalDataPolicy} />
                    <RouteWithLayout layout={GuestLayout} path="/contact-form" component={EmailMarketing} />
                    <RouteWithLayout layout={GuestLayout} path="/leads" component={LeadsMarketing} />
                    <Route component={NotFound} />
                </Switch>
                <ModalRoot />
            </ScrollToTop>
        </ConnectedRouter>
    </Provider>
);

export default App;
