import * as React from "react";
import ModalDialog from "./ModalDialog";
import { translate as t } from "../../../../l10n";
import { ModalType, Button } from '../types'

export interface ModalProps {
  item: {
    type: ModalType;
    button?: Button
  };
  onClose: (param?: object) => () => void;
}

const alertButtons: any = {
  type: 'primary',
  text: t("common", "button.ok"),
};

function Modal(props: ModalProps) {
  const { item, onClose } = props;
  const { type } = item;
  switch (type) {
    case "alert":
      return (<ModalDialog
        {...item}
        // alert modal has single button
        buttons={item.button ? [{ ...alertButtons, ...item.button }] : undefined}
        onClose={onClose()}
      />);
    case "confirm":
      return (<ModalDialog
        {...item}
        alertType="confirm"
        onClose={onClose({ confirm: false })}
        buttons={[{
          type: 'black',
          text: t("common", "button.cancel"),
          onClick: onClose({ confirm: false }),
        }, {
          type: 'primary',
          text: t("common", "button.ok"),
          onClick: onClose({ confirm: true }),
        }]
        }
      />);
    case "confirmYesNo":
      return (<ModalDialog
        {...item}
        alertType="confirm"
        onClose={onClose({ confirm: false })}
        buttons={[{
          type: 'black',
          text: t("common", "button.no"),
          onClick: onClose({ confirm: false }),
        }, {
          type: 'primary',
          text: t("common", "button.yes"),
          onClick: onClose({ confirm: true }),
        }]
        }
      />);
    case "confirmOkey":
      return (<ModalDialog
        {...item}
        alertType="confirm"
        onClose={onClose({ confirm: false })}
        buttons={[{
          type: 'primary',
          text: t("common", "button.ok"),
          onClick: onClose({ confirm: true }),
        }]
        }
      />);
    case "withoutIcon":
      return (<ModalDialog
        {...item}
        alertType="withoutIcon"
        onClose={onClose({ confirm: false })}
        buttons={[{
          type: 'primary',
          text: t("common", "button.ok"),
          onClick: onClose({ confirm: true }),
        }]
        }
      />);
    case "custom":
      return (<ModalDialog
        {...item}
        onClose={onClose()}
      />
      );
    default:
      return null;
  }
}


export default Modal;
