import { delay } from 'redux-saga'
import { call, takeLatest, put, take } from "redux-saga/effects"
import { AnyAction } from 'redux';
import { actionTypes as at, actionCreators as ac } from "./actions";
import { VmConfig } from './components/CloudCalculator/types'

const memo = {};

function generetaKey(config: VmConfig): string {
    return Object.keys(config).map((k) => config[k]).join(',');
}

export function* handleUpdatePrice({ payload }: AnyAction) {
    yield call(delay, 500);
    const key = generetaKey(payload);
    if (memo[key]) {
        yield put(ac.calculatePriceSuccess(memo[key]));
        return;
    }
    yield put(ac.calculatePrice(payload));
    const response = yield take(at.CALCULATE_PRICE_SUCCESS);
    memo[key] = response.payload;
}


export function* handleComponentReady() {

    yield put(ac.loadSettings());

    const config = { cpu: 1, ram: 512, hdd: 30 };
    yield put(ac.calculatePrice(config));

    const response = yield take(at.CALCULATE_PRICE_SUCCESS);
    const key = generetaKey(config);
    memo[key] = response.payload;

    yield takeLatest(at.UPDATE_PRICE, handleUpdatePrice);
}

export const sagas = function* () {
    yield takeLatest(at.COMPONENT_READY, handleComponentReady);
};