import { MOBILE_WIDTH } from '../../../../app/config';
import { translate } from '../../../../l10n';

const t = (str, context = 'common') => translate(context, str);

export const name = {
  name: 'name',
  type: 'text',
  label: t('form.firstName'),
  placeholder: t('form.insertFirstName'),
  minLength: 2,
  maxLength: 40,
  isOptional: true,
};
export const firstNameAndLastName = {
  name: 'firstNameAndLastName',
  type: 'text',
  label: t('form.firstNameAndLastName'),
  placeholder: t('form.insertfirstNameAndLastName'),
  minLength: 2,
  maxLength: 40,
  isOptional: false,
};
export const Name = {
  name: 'Name',
  type: 'text',
  label: t('form.firstNameAndLastName'),
  placeholder: t('form.insertfirstNameAndLastName'),
  minLength: 2,
  maxLength: 50,
  isOptional: false,
};
export const firstName = {
  name: 'firstName',
  type: 'text',
  label: t('form.firstName'),
  minLength: 2,
  maxLength: 45,
  placeholder: t('form.insertFirstName'),
};

export const lastName = {
  name: 'lastName',
  type: 'text',
  label: t('form.lastName'),
  minLength: 2,
  maxLength: 45,
  placeholder: t('form.insertLastName'),
};

export const email = {
  name: 'email',
  type: 'text',
  label: t('form.email'),
  placeholder: t('form.insertEmail'),
  autoComplete: true,
  value: ''
};
export const emailMarketing = {
  name: 'emailMarketing',
  type: 'text',
  label: t('form.email'),
  placeholder: t('form.insertEmail'),
  autoComplete: true,
  value: ''
};

export const password = {
  name: 'password',
  type: 'password',
  label: t('form.password'),
  minLength: 8,
  maxLength: 20,
  placeholder: t('form.insertPassword'),
  showPassword: true,
  showTooltipHints: window.innerWidth > MOBILE_WIDTH,
  value: ''
};

export const confirmPassword = {
  name: 'confirmPassword',
  type: 'password',
  label: t('form.confirmPassword'),
  minLength: 8,
  maxLength: 20,
  placeholder: t('form.insertConfirmPassword'),
};

export const phone = {
  name: 'phone',
  type: 'text',
  label: t('form.phone'),
  placeholder: t('form.insertPhone'),
  autoComplete: true,
  minLength: 3,
  maxLength: 30,
  isOptional: true,
};
export const PhoneNumber = {
  name: 'PhoneNumber',
  type: 'text',
  label: t('form.phone'),
  placeholder: t('form.insertPhone'),
  autoComplete: true,
  minLength: 3,
  maxLength: 30,
  isOptional: true,
};
export const captcha = {
  name: 'captcha',
  type: 'captcha',
  label: t('form.captcha'),
};

export const title = {
  name: 'title',
  type: 'text',
  label: t('form.title'),
  minLength: 3,
  maxLength: 200,
  placeholder: t('form.insertTitle'),
};

export const message = {
  name: 'message',
  type: 'textarea',
  label: t('form.text'),
  minLength: 6,
  maxLength: 2000,
  placeholder: t('form.insertText'),
};

export const sendMeCopy = {
  name: 'sendMeCopy',
  type: 'checkbox',
  label: t('form.sendMeCopy'),
  isOptional: true,
  checkboxHuge: true, // different looking checkbox in guest layout(registration contact us form) default is false
};

export const acceptInstructions = {
  name: 'acceptInstructions',
  type: 'checkbox',
  label: t('form.acceptInstructions'),
  message: t('form.requiredFieldTemplate')(t('form.acceptInstructions')),
};


export const accessCode = {
  name: 'accessCode',
  type: 'text',
  label: t('form.accessCode'),
  minLength: 3,
  maxLength: 20,
  placeholder: t('form.insertAccessCode'),
};

export const requestForDeactivation = {
  name: 'requestForDeactivation',
  type: 'textarea',
  label: t('account.deactivationRequestWhy'),
  placeholder: t('account.deactivationRequestTextPopUp'),
  isOptional: false,
  autoComplete: true,
  minLength: 50,
  valid: false,
}

// export const city = {
//   name: 'city',
//   type: 'text',
//   label: t('form.city'),
//   placeholder: t('form.insertCity'),
//   minLength: 2,
//   maxLength: 20,
// }

export const firstNameLeads = {
  name: 'firstNameLeads',
  type: 'text',
  label: t('form.firstName'),
  minLength: 2,
  maxLength: 40,
  placeholder: t('form.firstName'),
};
export const lastNameLeads = {
  name: 'lastNameLeads',
  type: 'text',
  label: t('form.lastName'),
  minLength: 2,
  maxLength: 80,
  placeholder: t('form.lastName'),
};
export const emailLeads = {
  name: 'emailLeads',
  type: 'text',
  label: t('form.email'),
  minLength: 2,
  maxLength: 80,
  placeholder: t('form.email'),
};
export const phoneLeads = {
  name: 'phoneLeads',
  type: 'text',
  label: t('form.phone'),
  minLength: 2,
  maxLength: 40,
  placeholder: t('form.phone'),
  isOptional: false,
};

export const companyLeads = {
  name: 'companyLeads',
  type: 'text',
  label: t('form.companyName'),
  minLength: 2,
  maxLength: 40,
  placeholder: t('form.companyName'),
};
export const mobileLeads = {
  name: 'mobileLeads',
  type: 'text',
  label: t('form.mobilePhone'),
  minLength: 2,
  maxLength: 40,
  placeholder: t('form.mobilePhone'),
};
export const cityLeads = {
  name: 'cityLeads',
  type: 'text',
  label: t('form.city'),
  minLength: 2,
  maxLength: 40,
  placeholder: t('form.city'),
};
export const stateLeads = {
  name: 'stateLeads',
  type: 'text',
  label: t('form.state'),
  minLength: 2,
  maxLength: 20,
  placeholder: t('form.state'),
};
export const descriptionLeads = {
  name: 'descriptionLeads',
  type: 'textarea',
  label: t('form.description'),
  placeholder: t('form.description'),
  isOptional: false,
  autoComplete: true,
  minLength: 0,
  valid: false,
}
export const industryLeads = {
  name: 'industryLeads',
  type: 'select-leads',
  label: t('form.industry'),
  options: [],
  selected: 0,
};
export const emailOptOutLeads = {
  name: 'emailOptOutLeads',
  type: 'checkbox',
  label: t('form.emailOptOutLeads'),
  isOptional: true,
  value: false,
  checkboxHuge: true, // different looking checkbox in guest layout(registration contact us form) default is false
};
export const doNotCallLeads = {
  name: 'doNotCallLeads',
  type: 'checkbox',
  label: t('form.doNotCallLeads'),
  isOptional: true,
  value: false,
  checkboxHuge: true, // different looking checkbox in guest layout(registration contact us form) default is false
};
export const titleLeads = {
  name: 'titleLeads',
  type: 'text',
  label: t('form.title2'),
  isOptional: false,
  maxLength: 40,
  placeholder: t('form.title2'),
}

export const streetLeads = {
  name: 'streetLeads',
  type: 'text',
  label: t('form.address'),
  isOptional: false,
  placeholder: t('form.address'),
}

export const websiteLeads = {
  name: 'websiteLeads',
  type: 'text',
  label: t('form.website'),
  isOptional: true,
  maxLength: 40,
  placeholder: t('form.website'),
}

export const industryOptions = ["--None--", "Agriculture", "Apparel", "Banking", "Biotechnology", "Chemicals", "Communications", "Construction", "Consulting", "Education", "Energy", "Engineering", "Entertainment", "Environmental", "Finance", "Food", "Government", "Healthcare", "Hospitality", "Insurance", "Machinery", "Manufacturing", "Media", "Not For Profit", "Other", "Recreation", "Retail", "Shipping", "Technology", "Telecommunications", "Transportation", "Utilities"];
industryOptions.map(index => industryLeads.options.push({ value: index }));
