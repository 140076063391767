import keyMirror from "key-mirror";
import { createStandardAction } from "typesafe-actions";

export const actionTypes = keyMirror({
  SEND_ACTIVATION: null,
  SEND_ACTIVATION_ERROR: null,
  SEND_ACTIVATION_SUCCESS: null,

  IS_ACCOUNT_DEACTIVATED: null,
  IS_ACCOUNT_DEACTIVATED_ERROR: null,
  IS_ACCOUNT_DEACTIVATED_SUCCESS: null,
});

const at = actionTypes;

export const actionCreators = {
  sendActivation: createStandardAction(at.SEND_ACTIVATION).map(
    (payload: { email: string; }) => ({
      payload,
      meta: {
        request: {
          url: '/Home/InactiveAccount',
          method: 'POST',
          body: JSON.stringify(payload),
        },
      }
    })
  ),

  isAccountDeactivated: createStandardAction(at.IS_ACCOUNT_DEACTIVATED).map(
    (payload: { email: string; }) => ({
      payload,
      meta: {
        request: {
          url: '/Home/IsAccountDeactivated',
          method: 'POST',
          body: JSON.stringify(payload),
        },
      }
    })
  ),
}