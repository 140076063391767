import * as React from "react";
import { connect } from "react-redux";
import { showModal, hideModal } from "../actions";
import { Modal as IModal } from '../types'
import { getModals } from "../reducer";
import Modal from "./Modal";
import ModalPortal from "./ModalPortal";

export interface ModalRootProps {
  modals?: IModal[];
  hideModal: (modal: IModal) => void;
}

function ModalRoot(props: ModalRootProps) {
  const { modals = [], hideModal } = props;
  if (modals.length === 0) {
    return null;
  }

  const modal: IModal = modals.slice(-1)[0]; // get last modal in collection
  const onCloseModal = (params) => () => {
    if (modal.onClose) {
      modal.onClose(params);
    }
    hideModal({ id: modal.id, ...params });
  }

  return (
    <ModalPortal>
      <div className="rc-dialog-mask" />
      <Modal
        item={modal}
        onClose={onCloseModal}
      />
    </ModalPortal>
  );
}

const mapDispatchToProps = {
  showModal,
  hideModal,
};

const mapStateToProps = state => ({
  modals: getModals(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRoot);
