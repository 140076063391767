import * as React from "react";
import "./styles/PasswordStrengthMeter.scss";
import { translate } from "../../../l10n";

const t = (str, context = "common") => translate(context, str);

const strengthTypes = {
  poor: {
    color: "red",
    label: t("passwordStrengthMeter.veryWeak"),
  },
  weak: {
    color: "red",
    label: t("passwordStrengthMeter.weak"),
  },
  good: {
    color: "orange",
    label: t("passwordStrengthMeter.average"),
  },
  strong: {
    color: "lightgreen",
    label: t("passwordStrengthMeter.average"),
  },
  veryStrong: {
    color: "green",
    label: t("passwordStrengthMeter.strong"),
  },
};

export const strengthInfo = (strength) => {
  const types = strengthTypes;
  if (strength <= 2) {
    return types.poor;
  }

  if (strength < 3) {
    return types.weak;
  }
  if (strength < 4) {
    return types.good;
  }

  if (strength < 5) {
    return types.strong;
  }

  if (strength < 6) {
    return types.veryStrong;
  }
};

type PasswordStrengthMeterProps = {
  strength: number;
};

export const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = (
  props
) => {
  const { strength } = props;

  const strengthProgress = () => {
    let progress = 0;
    if (strength === 1) progress = 5;
    if (strength === 2) progress = 10;
    if (strength >= 2) progress = 35;

    if (strength >= 3) progress = 60;

    if (strength >= 4) progress = 70;

    if (strength >= 5) progress = 100;

    return `${progress}%`;
  };

  const { color, label } = strengthInfo(strength);

  return (
    <div className="password-strength">
      <div className="progress-bar-wrapper">
        <div
          className="progress-bar"
          style={{ backgroundColor: color, width: strengthProgress() }}
        />
      </div>
      {label}
    </div>
  );
};

export default PasswordStrengthMeter;
