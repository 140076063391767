import * as React from 'react';
import FooterLink from '../../../shared/components/FooterLink';
import InnerHTML from '../../../shared/components/InnerHTML';
import './styles/Footer.scss';
import { translate } from '../../../l10n';
const cookies = require('browser-cookies');

const t = (str: string, context = 'common') => translate(context, str);

export interface FooterProps {
    url: string;
}

const Footer = ({ url }: FooterProps) => {
    const ToUIv1 = (e) => {
        e.preventDefault;
        cookies.set('OblakBGUIVersion', 'v1');
        location.reload();
    }

    return (
    <footer className="footer">
        <div className="footer__container">
            <i className="footer__logo icon-oblak" />
            <div className="footer__content">
                <div className="footer__text">
                    <p><InnerHTML str={t('footer.text')} /></p>
                </div>
                <ul>
                    <FooterLink to="/terms" label={t('footer.terms')} className="footer__link--first" url={url} />
                    <FooterLink to="/service-level" label={t('footer.serviceLevel')} url={url} />
                    <FooterLink to="/privacy" label={t('footer.privacy')} url={url} />
                    <FooterLink to="/cookie-policy" label={t('footer.cookiePolicy')} url={url} />
                    <FooterLink to="/personal-policy" label={t('footer.personalPolicy')} url={url} />
                    <FooterLink to="/contact-us" label={t('contactUs')} url={url} />
                    <li className="footer__link footer__link--phone">
                        <a onClick={(e) => ToUIv1(e)}>{t('previousUI')}</a>
                    </li>
                    <li className="footer__link footer__link--last">
                        <i className="footer__icon-phone icon-phone" />
                        <span>{t('phoneNumber')}</span>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
)};

export default Footer;
