import { fork } from 'redux-saga/effects';
import { sagas as fetchSagas } from '../shared/sagas/services/sagas';
import { sagas as commonSagas } from '../shared/sagas/common/sagas';
import { sagas as calculatorSagas } from './index-guest/sagas';
import { sagas as sharedConsole } from '../shared/pages/SharedConsole/sagas';
import { sagas as consoleScreen } from '../shared/components/ConsoleScreen/sagas';
import { sagas as accountVerification } from './confirm-registration/sagas';

export default function* sagas() {
  yield fork(fetchSagas);
  yield fork(commonSagas);
  yield fork(calculatorSagas);
  yield fork(sharedConsole);
  yield fork(consoleScreen);
  yield fork(accountVerification);
}
