import asyncComponent from './asyncComponent';
import NotFound from '../pages/NotFound';

// // shared (async) pages
const ContactUs = asyncComponent(() => import(/* webpackChunkName: "contact-us" */'../pages/ContactUs'));
const Terms = asyncComponent(() => import(/* webpackChunkName: "terms" */'../pages/Terms'));
const ServiceLevel = asyncComponent(() => import(/* webpackChunkName: "service-level" */'../pages/ServiceLevel'));
const PrivacyPolicy = asyncComponent(() => import(/* webpackChunkName: "privacy-policy" */'../pages/PrivacyPolicy'));
const CookiePolicy = asyncComponent(() => import(/* webpackChunkName: "cookie-policy" */'../pages/CookiePolicy'));
const PersonalDataPolicy = asyncComponent(() => import(/* webpackChunkName: "personal-data-policy" */'../pages/PersonalDataPolicy'));
const EmailMarketing = asyncComponent(() => import(/* webpackChunkName: "personal-data-policy" */'../pages/EmailMarketing'));
const LeadsMarketing = asyncComponent(() => import(/* webpackChunkName: "personal-data-policy" */'../pages/LeadsMarketing'));


// const asyncComponents = [Terms, ContactUs, ServiceLevel, PrivacyPolicy, CookiePolicy, PersonalDataPolicy];

// /**
//  * Then iterate over all of the matched routes, if they've got a load function
//  * call it.
//  * This helps us to make sure all the async code is loaded before rendering.
//  */
// export function ensureReady() {
//     return Promise.all(asyncComponents.map((component) => {
//         if (component && component.load) {
//         return component.load();
//         }
//         return undefined;
//     }));
// }

export { Terms, ContactUs, ServiceLevel, PrivacyPolicy, CookiePolicy, PersonalDataPolicy, NotFound, EmailMarketing, LeadsMarketing };  