import * as React from 'react';
import { Link } from 'react-router-dom';
import './styles/Header.scss';

export interface HeaderProps {
    children: React.ReactNode;
}


export class Header extends React.PureComponent<HeaderProps> {

    render() {
        const { children } = this.props;
        return (
            <header className="header">
                <div className="header__logo">
                    <Link to="/" aria-label="Oblak.bg">
                        <span className="header__logo-icon" />
                    </Link>
                </div>
                {children}
            </header>
        );
    }
}

export default Header;
