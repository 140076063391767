import * as React from 'react';
import RegistrationForm from './RegistrationForm';

import { translate } from '../../../l10n';

const RegistrationPage = props => (
    <div className="form-page">
        <h1 className="form-page__title">
            {translate('guest', 'registration.title')}
        </h1>
        <RegistrationForm {...props} />
    </div>);

export default RegistrationPage;

