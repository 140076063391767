import * as React from 'react';
import classNames from "classnames";
import { AlertType } from '../types';


type ModalIconProps = {
    type: AlertType | "confirm";
};

const ModalIcon: React.FC<ModalIconProps> = props => {
    const { type } = props;

    const ringClass = classNames("alert-icon__ring", {
        "alert-icon__ring--warn": type === "warning",
        "alert-icon__ring--error": type === "error",
        "alert-icon__ring--success": type === "success",
        "alert-icon__ring": type === "confirm",
    });
    const iconClass = classNames({
        "alert-icon--warn": type === "warning",
        "alert-icon--error": type === "error",
        "alert-icon--success": type === "success",
        "alert-icon--confirm": type === "confirm",
    });
    return (
        <div className="alert-icon">
            <span className={ringClass} />
            <span className={iconClass}>{type === "confirm" ? "?" : null}</span>
        </div>
    )
};


export default ModalIcon;