import * as React from 'react';
import { connect } from 'react-redux';
import Button from '../../../shared/components/Button';
import { isLoading, getData } from '../reducer/acountActivation';
import { getData as getDeactivationData } from '../reducer/isAccountDeactivated';
import { actionCreators as ac } from '../actions';
import { showModal } from '../../../shared/sagas/modals/actions';
import { translate as t } from '../../../l10n';
import { History } from "history";

export interface InactiveAccountProps {
  match: {
    params: {
      email: string;
    },
    url: string;
  };
  history: History;
  showModal: (meta) => void;
  sendActivation: (arg: {
    email: string;
  }) => void;
  accountDeactivate: any;
  isAccountDeactivated: ({ email: string }) => void;
}

interface State {
  isAccountDeactivated: boolean;
}

class InactiveAccount extends React.Component<InactiveAccountProps, State> {
  private email: string | null;
  history: History;
  constructor(props: InactiveAccountProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.state = {
      isAccountDeactivated: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, accountDeactivate } = nextProps;
    console.log(nextProps);
    if (accountDeactivate.message == "AccountIsDeactivated") {
      this.setState({
        isAccountDeactivated: true
      })
    }
    if (data) {
      let msgPopup = "";
      const { status } = data;
      if (status === "error") {
        msgPopup = this.state.isAccountDeactivated ? t('guest', "deactivatedAccount.errorMessage") : t('guest', "inactiveAccount.errorMessage");
      }
      else {
        msgPopup = this.state.isAccountDeactivated ? t('guest', "deactivatedAccount.successMessage") : t('guest', "inactiveAccount.successMessage");
      }
      this.props.showModal({
        type: 'alert',
        alertType: (status === 'error') ? 'error' : 'success',
        message: msgPopup,
        onClose: () => {
          this.props.history.push("/");
        },
      });
    }
  }

  componentDidMount() {
    const emailHash = window.location.href;
    const getEmailFromHash = emailHash.split("/inactive-account/?email=");
    this.email = getEmailFromHash[1];
    this.props.isAccountDeactivated({ email: this.email });
  }

  handleClick() {
    this.props.sendActivation({ email: this.email });
  }

  handleCancelClick() {
    window.location.hash = '/';
  }

  render() {
    const { isAccountDeactivated } = this.state;
    return (<div className="form-page">
      <div className="mesage-info">
        <p>{!isAccountDeactivated ? t('guest', 'inactiveAccount.message') : t('guest', 'deactivatedAccount.message')}</p>
      </div>
      <Button
        rounded
        gradient="light"
        onClick={this.handleClick}
        className="mt-30"
      >
        {t('common', 'button.send')}
      </Button>
      {!isAccountDeactivated ? <span></span> :
        <Button
          rounded
          style={{ marginLeft: "10px" }}
          gradient="light"
          onClick={this.handleCancelClick}
          className="mt-30"
        >
          {t('common', 'button.cancel')}
        </Button>
      }
    </div>);
  }
}

const mapStateToProps = state => ({
  loading: isLoading(state),
  data: getData(state),
  accountDeactivate: getDeactivationData(state),
});

const mapDispatchToProps = {
  sendActivation: ac.sendActivation,
  isAccountDeactivated: ac.isAccountDeactivated,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InactiveAccount);

